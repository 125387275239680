<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="less">
@import "style/common";
</style>

<script>
export default {
  mounted() {
    // this.listenerWindow();
    //
    // window.addEventListener("resize", () => {
    //   this.listenerWindow();
    // });
  },
  methods: {
    listenerWindow() {
      let r = (window.innerWidth / 1920) * 62.5;
      if (window.innerWidth <= 500) {
        r = 30;
      }

      console.log(r, "rrr");
      document.documentElement.style.fontSize = r + "%";
    },
  },
};
</script>
