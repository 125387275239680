import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // redirect: "/index",
    name: "Layout",
    component: () => import("../views/layout.vue"),
    children: [
      {
        path: "/",
        name: "谷道合 - 官网",
        component: () => import("../views/index/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  // 设置页面title
  document.title = to.name;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
});

export default router;
